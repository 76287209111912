// import React, { useState, useEffect } from "react";
// import "./project.css";

// const Projects = () => {
//   const [showButton, setShowButton] = useState(true);
//   const [error, setError] = useState("");
//   const [uploadMessage, setUploadMessage] = useState(
//     "Please Upload your menu sheet here..! It must be PDF only"
//   );
//   const [marketingMsg, setMarketingMsg] = useState("");
//   const [isEditingMsg, setIsEditingMsg] = useState(false);

//   useEffect(() => {
//     // Fetch the current marketing message from the API
//     const fetchMarketingMessage = async () => {
//       try {
//         const response = await fetch('https://leadapi.ivoz.ai/chicago-pizza/initial-greetings', {
//           method: 'GET',
//           headers: {
//             'Accept': 'application/json',
//           },
//         });
//         const result = await response.json();
//         if (response.ok) {
//           setMarketingMsg(result.message || "I have a great news for you..... We’ve recently launched our large Parantha Pizza at 50% discount!");
//         } else {
//           console.error('Failed to fetch marketing message.');
//         }
//       } catch (error) {
//         console.error('Error fetching marketing message:', error);
//       }
//     };

//     fetchMarketingMessage();
//   }, []);

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     if (file.type !== "application/pdf") {
//       setError("Please upload a PDF file.");
//       return;
//     }

//     setError("");  // Clear any previous errors
//     setUploadMessage("Your menu is being uploaded...");

//     // Prepare FormData for API upload
//     const formData = new FormData();
//     formData.append("file", file);

//     // Upload the file to the API
//     try {
//       const response = await fetch("https://leadapi.ivoz.ai/chicago-pizza/upload-kb", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//         },
//         body: formData,
//       });
//       const result = await response.json();
//       if (result.status) {
//         setUploadMessage("Your menu has been successfully uploaded.");
//         console.log(result.message);
//       } else {
//         setUploadMessage("Failed to upload the file to the server.");
//         console.error('Failed to upload the file to the server');
//       }
//     } catch (error) {
//       setUploadMessage("Error uploading file.");
//       console.error("Error uploading file:", error);
//     }

//     setShowButton(false);
//   };

//   const handleEditMarketingMsg = () => {
//     setIsEditingMsg(true);
//   };

//   const handleSaveMarketingMsg = async () => {
//     setIsEditingMsg(false);
//     localStorage.setItem("marketingMessage", marketingMsg);

//     // API call to update the marketing message
//     try {
//       const response = await fetch(
//         `https://leadapi.ivoz.ai/chicago-pizza/initial-greetings?greeting_text=${encodeURIComponent(marketingMsg)}`,
//         {
//           method: 'POST',
//           headers: {
//             'Accept': 'application/json',
//           },
//         }
//       );
//       const result = await response.json();
//       if (response.ok) {
//         alert(result.message);
//       } else {
//         alert('Failed to update greeting text.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred while updating the greeting text.');
//     }
//   };

//   const handleMarketingMsgChange = (e) => {
//     setMarketingMsg(e.target.value);
//   };

//   return (
//     <div className="project-container">
//       <div className="marketing-msg">
//         <div className="news-message">
//           {isEditingMsg ? (
//             <div>
//               <textarea
//                 value={marketingMsg}
//                 onChange={handleMarketingMsgChange}
//                 rows="4"
//                 cols="50"
//               />
//               <button className="beautiful-button" onClick={handleSaveMarketingMsg}>
//                 Save Messages
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2 className="marketMsg">{marketingMsg}</h2>
//               <button className="beautiful-button" onClick={handleEditMarketingMsg}>
//                 Edit
//               </button>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="excel">
//         <h2>{uploadMessage}</h2>
//         {showButton && (
//           <div>
//             <div className="file-input">
//               <input
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleFileUpload}
//                 style={{ marginBottom: "20px" }}
//               />
//               <span className="button">Choose</span>
//               <span className="label" data-js-label>
//                 No file selected
//               </span>
//             </div>

//             {error && <p style={{ color: "red" }}>{error}</p>}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Projects;



// import React, { useState, useEffect } from "react";
// import "./project.css";

// const Projects = () => {
//   const [showButton, setShowButton] = useState(true);
//   const [error, setError] = useState("");
//   const [uploadMessage, setUploadMessage] = useState(
//     "Please Upload your menu sheet here..! It must be PDF only"
//   );
//   const [marketingMsg, setMarketingMsg] = useState("");
//   const [isEditingMsg, setIsEditingMsg] = useState(false);

//   useEffect(() => {
//     // Fetch the current marketing message from the API
//     const fetchMarketingMessage = async () => {
//       try {
//         const response = await fetch('https://leadapi.ivoz.ai/chicago-pizza/initial-greetings', {
//           method: 'GET',
//           headers: {
//             'Accept': 'application/json',
//           },
//         });
//         const result = await response.json();
//         if (response.ok) {
//           setMarketingMsg(result.message || "I have a great news for you..... We’ve recently launched our large Parantha Pizza at 50% discount!");
//         } else {
//           console.error('Failed to fetch marketing message.');
//         }
//       } catch (error) {
//         console.error('Error fetching marketing message:', error);
//       }
//     };

//     fetchMarketingMessage();
//   }, []);

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     if (file.type !== "application/pdf") {
//       setError("Please upload a PDF file.");
//       return;
//     }

//     setError("");  // Clear any previous errors
//     setUploadMessage("Your menu is being uploaded...");

//     // Prepare FormData for API upload
//     const formData = new FormData();
//     formData.append("file", file);

//     // Upload the file to the API
//     try {
//       const response = await fetch("https://leadapi.ivoz.ai/chicago-pizza/upload-kb", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//         },
//         body: formData,
//       });
//       const result = await response.json();
//       if (result.status) {
//         setUploadMessage("Your menu has been successfully uploaded.");
//         console.log(result.message);
//       } else {
//         setUploadMessage("Failed to upload the file to the server.");
//         console.error('Failed to upload the file to the server');
//       }
//     } catch (error) {
//       setUploadMessage("Error uploading file.");
//       console.error("Error uploading file:", error);
//     }

//     setShowButton(false);
//   };

//   const handleEditMarketingMsg = () => {
//     setIsEditingMsg(true);
//   };

//   const handleSaveMarketingMsg = async () => {
//     setIsEditingMsg(false);
//     localStorage.setItem("marketingMessage", marketingMsg);

//     // API call to update the marketing message
//     try {
//       const response = await fetch(
//         `https://leadapi.ivoz.ai/chicago-pizza/initial-greetings?greeting_text=${encodeURIComponent(marketingMsg)}`,
//         {
//           method: 'POST',
//           headers: {
//             'Accept': 'application/json',
//           },
//         }
//       );
//       const result = await response.json();
//       if (response.ok) {
//         alert(result.message);
//       } else {
//         alert('Failed to update greeting text.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred while updating the greeting text.');
//     }
//   };

//   const handleMarketingMsgChange = (e) => {
//     setMarketingMsg(e.target.value);
//   };

//   return (
//     <div className="project-container">
//       <div className="marketing-msg">
//         <div className="news-message">
//           {isEditingMsg ? (
//             <div>
//               <textarea
//                 value={marketingMsg}
//                 onChange={handleMarketingMsgChange}
//                 rows="4"
//                 cols="50"
//               />
//               <button className="beautiful-button" onClick={handleSaveMarketingMsg}>
//                 Save Messages
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2 className="marketMsg">{marketingMsg}</h2>
//               <button className="beautiful-button" onClick={handleEditMarketingMsg}>
//                 Edit
//               </button>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="excel">
//         <h2>{uploadMessage}</h2>
//         {showButton && (
//           <div>
//             <div className="file-input">
//               <input
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleFileUpload}
//                 style={{ marginBottom: "20px" }}
//               />
//               <span className="button">Choose</span>
//               <span className="label" data-js-label>
//                 No file selected
//               </span>
//             </div>

//             {error && <p style={{ color: "red" }}>{error}</p>}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Projects;



import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './mainboard.css';

export default function Projects() {
  const [bots, setBots] = useState([]); // State to store bots data

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBots(data.bots);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchAccountDetails();
  }, []);

  return (
    <div>
       <div className="history">
          <h2>Campaigns</h2>
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Bot ID</th>
                <th>Bot Name</th>
                <th>Bot Phone</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bots.map((bot, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{bot.bot_id}</td>
                  <td>{bot.bot_name}</td>
                  <td>{bot.bot_phone}</td>
                  <td className="warning">Pending</td>
                  <td className="primary">
                    <Link to={`/userboard/bot-details/${bot.bot_id}`}>Details</Link> {/* Link to the details page */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link to="#">Show All</Link>
        </div>
    </div>
  );
}
