import React from "react";

export default function Profile() {
  return (
    <div>
      <h1>Profile</h1>
      <hr />
      <div className="profileInputs">
        <div className="profileInner">
          <label htmlFor="First Name">First Name</label>
          <br />
          <input type="text" />
        </div>
        <div className="profileInner">
          <label htmlFor="Last Name">Last Name</label>
          <br />
          <input type="text" />
        </div>
        <div className="profileInner">
          <label htmlFor="Email">Email</label>
          <br />
          <input type="email" />
        </div>
        <div className="profileInner">
          <label htmlFor="Phone Number">Phone Number</label>
          <br />
          <input type="Phone" />
        </div>
        <div className="profileInner">
          <label htmlFor="Address">Address</label>
          <br />
          <input type="text" />
        </div>
        <div className="profileInner">
          <label htmlFor="Nation">Nation</label>
          <br />
          <input type="text" />
        </div>
      </div>

      <hr />
      <div className="accessButton">
        <div className="updateButton">
          <button>Update</button>
        </div>
        <div className="cancelButton">
          <button>Cancel</button>
        </div>
      </div>
    </div>
  );
}
