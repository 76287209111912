// src/SearchBar.js
// import React, { useState, useEffect } from 'react';

// const AdminProfile = () => {
//   const [query, setQuery] = useState('');
//   const [results, setResults] = useState([]);
//   const [allUsers, setAllUsers] = useState([]); // State to store all users

//   useEffect(() => {
//     const fetchResults = async () => {
//       try {
//         const response = await fetch('https://devapi.ivoz.ai/admin/users?page=1&per_page=10', {
//           headers: {
//             'accept': 'application/json',
//             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGV4YW1wbGUuY29tIiwicm9sZXMiOlsic3RhbmRhcmRfdXNlciIsImFkbWluIl0sImV4cCI6MTcyNjE0MzM5OH0.VCxIrTfkjOutvtK0V94TR4OHOld72tV_3R1aZDZeyQw'
//           },
//         });
//         const data = await response.json();
//         setAllUsers(data); // Store all users
//       } catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };

//     fetchResults();
//   }, []);

//   // Filter results based on query for email
//   useEffect(() => {
//     if (query.length > 0) {
//       const filteredResults = allUsers.filter((user) =>
//         user.email.toLowerCase().includes(query.toLowerCase())
//       );
//       setResults(filteredResults);
//     } else {
//       setResults([]); // Clear results if query is empty
//     }
//   }, [query, allUsers]);

//   return (
//     <div className="search-container">
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         placeholder="Search users by email..."
//       />
//       <ul>
//         {results.map((user) => (
//           <li key={user.id}>{user.email}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default AdminProfile;

// src/SearchBar.js
// import React, { useState, useEffect } from 'react';

// const AdminProfile = () => {
//   const [query, setQuery] = useState('');
//   const [results, setResults] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [apiResponse, setApiResponse] = useState([]);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (query.length > 0) {
//         try {
//           const response = await fetch(`https://devapi.ivoz.ai/admin/users?page=1&per_page=10`, {
//             headers: {
//               'accept': 'application/json',
//               'Authorization': 'Bearer YOUR_TOKEN_HERE' // Update with your actual token
//             }
//           });

//           if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//           }

//           const data = await response.json();
//           console.log('API Response:', data);  // Log the data to see its structure

//           if (Array.isArray(data)) {
//             const filteredResults = data.filter(user =>
//               user.email.toLowerCase().includes(query.toLowerCase())
//             );
//             setResults(filteredResults);
//           } else {
//             setError('Unexpected response format.');
//           }
//         } catch (error) {
//           console.error('Error fetching user data:', error);
//           setError(error.message);
//         }
//       } else {
//         setResults([]);
//       }
//     };

//     fetchResults();
//   }, [query]);

//   const handleSearch = async () => {
//     if (selectedUser) {
//       try {
//         const response = await fetch(`https://devapi.ivoz.ai/admin/users?email=${encodeURIComponent(selectedUser.email)}`, {
//           method: 'POST',
//           headers: {
//             'accept': 'application/json',
//             'Authorization': 'Bearer YOUR_TOKEN_HERE' // Update with your actual token
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('Post Search Response:', data);  // Log the data to see its structure

//         if (Array.isArray(data)) {
//           setApiResponse(data);  // Set API response to state
//         } else {
//           setError('Unexpected response format.');
//         }
//       } catch (error) {
//         console.error('Error performing search:', error);
//         setError(error.message);
//       }
//     }
//   };

//   return (
//     <div className="search-container">
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         placeholder="Search users..."
//         onKeyDown={(e) => {
//           if (e.key === 'Enter') {
//             handleSearch();  // Perform search on Enter key
//           }
//         }}
//       />
//       <button onClick={handleSearch}>Submit</button>
//       <ul>
//         {results.map((user) => (
//           <li key={user.id} onClick={() => setSelectedUser(user)}>
//             {user.email}
//           </li>
//         ))}
//       </ul>
//       {apiResponse.length > 0 && (
//         <div className="api-response">
//           <h2>API Response:</h2>
//           <ul>
//             {apiResponse.map(user => (
//               <li key={user.id}>
//                 ID: {user.id}, Name: {user.name}, Email: {user.email}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default AdminProfile;

// src/AdminProfile.jsx
// import React, { useState, useEffect, useCallback } from 'react';

// const AdminProfile = () => {
//   const [query, setQuery] = useState('');
//   const [results, setResults] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [apiResponse, setApiResponse] = useState([]);
//   const [error, setError] = useState('');

//   // Function to get the token from local storage
//   const getToken = () => {
//     return localStorage.getItem('access_token'); // Replace 'token' with the key you're using
//   };

//   // Fetch user data based on the query
//   const fetchResults = useCallback(async () => {
//     const token = getToken(); // Retrieve token from local storage

//     if (query.length > 0) {
//       try {
//         const response = await fetch(`https://devapi.ivoz.ai/admin/users?page=1&per_page=10`, {
//           headers: {
//             'accept': 'application/json',
//             'Authorization': `Bearer ${token}` // Use token from local storage
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('API Response:', data);

//         if (Array.isArray(data)) {
//           const filteredResults = data.filter(user =>
//             user.email.toLowerCase().includes(query.toLowerCase())
//           );
//           setResults(filteredResults);
//         } else {
//           setError('Unexpected response format.');
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         setError('Failed to fetch user data. Please check your authorization.');
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query]);

//   // Handle search when the button is clicked or Enter key is pressed
//   const handleSearch = async () => {
//     const token = getToken(); // Retrieve token from local storage

//     if (selectedUser) {
//       try {
//         const response = await fetch(`https://devapi.ivoz.ai/admin/users?email=${encodeURIComponent(selectedUser.email)}`, {
//           method: 'POST',
//           headers: {
//             'accept': 'application/json',
//             'Authorization': `Bearer ${token}` // Use token from local storage
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('Post Search Response:', data);

//         if (Array.isArray(data)) {
//           setApiResponse(data);
//         } else {
//           setError('Unexpected response format.');
//         }
//       } catch (error) {
//         console.error('Error performing search:', error);
//         setError('Failed to perform search. Please check your authorization.');
//       }
//     }
//   };

//   // Fetch results whenever query changes
//   useEffect(() => {
//     fetchResults();
//   }, [fetchResults]); // Include fetchResults in dependency array

//   return (
//     <div className="search-container">
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         placeholder="Search users..."
//         onKeyDown={(e) => {
//           if (e.key === 'Enter') {
//             handleSearch();
//           }
//         }}
//       />
//       <button onClick={handleSearch}>Submit</button>
//       <ul>
//         {results.map((user) => (
//           <li key={user.id} onClick={() => setSelectedUser(user)}>
//             {user.email}
//           </li>
//         ))}
//       </ul>
//       {apiResponse.length > 0 && (
//         <div className="api-response">
//           <h2>API Response:</h2>
//           <ul>
//             {apiResponse.map(user => (
//               <li key={user.id}>
//                 ID: {user.id}, Name: {user.name}, Email: {user.email}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default AdminProfile;

// import React, { useState, useEffect, useCallback } from 'react';

// const AdminProfile = () => {
//   const [query, setQuery] = useState('');
//   const [results, setResults] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [apiResponse, setApiResponse] = useState([]);
//   const [error, setError] = useState('');

//   // Function to get the token from local storage
//   const getToken = () => {
//     return localStorage.getItem('access_token'); // Replace 'token' with the key you're using
//   };

//   // Fetch user data based on the query
//   const fetchResults = useCallback(async () => {
//     const token = getToken(); // Retrieve token from local storage

//     if (query.length > 0) {
//       try {
//         const response = await fetch(`https://devapi.ivoz.ai/admin/users?page=1&per_page=10`, {
//           headers: {
//             'accept': 'application/json',
//             'Authorization': `Bearer ${token}` // Use token from local storage
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('API Response:', data);

//         if (Array.isArray(data)) {
//           const filteredResults = data.filter(user =>
//             user.email.toLowerCase().includes(query.toLowerCase())
//           );
//           setResults(filteredResults);
//         } else {
//           setError('Unexpected response format.');
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         setError('Failed to fetch user data. Please check your authorization.');
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query]);

//   // Handle search when the button is clicked or Enter key is pressed
//   const handleSearch = async () => {
//     const token = getToken(); // Retrieve token from local storage

//     if (selectedUser) {
//       try {
//         const response = await fetch(`https://devapi.ivoz.ai/admin/users?email=${encodeURIComponent(selectedUser.email)}`, {
//           method: 'POST',
//           headers: {
//             'accept': 'application/json',
//             'Authorization': `Bearer ${token}` // Use token from local storage
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('Post Search Response:', data);

//         if (Array.isArray(data)) {
//           setApiResponse(data);
//         } else {
//           setError('Unexpected response format.');
//         }
//       } catch (error) {
//         console.error('Error performing search:', error);
//         setError('Failed to perform search. Please check your authorization.');
//       }
//     }
//   };

//   // Fetch results whenever query changes
//   useEffect(() => {
//     fetchResults();
//   }, [fetchResults]); // Include fetchResults in dependency array

//   return (
//     <div className="search-container">
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         placeholder="Search users..."
//         onKeyDown={(e) => {
//           if (e.key === 'Enter') {
//             handleSearch();
//           }
//         }}
//       />
//       <button onClick={handleSearch}>Submit</button>
//       {query.length > 0 && (
//         <ul className="search-results">
//           {results.map((user) => (
//             <li
//               key={user.id}
//               onClick={() => {
//                 setSelectedUser(user);
//                 setQuery(user.email); // Optionally, set the query to the selected email
//                 setResults([]); // Clear results after selection
//               }}
//               style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #ddd' }}
//             >
//               {user.email}
//             </li>
//           ))}
//         </ul>
//       )}
//       {apiResponse.length > 0 && (
//         <div className="api-response">
//           <h2>API Response:</h2>
//           <ul>
//             {apiResponse.map(user => (
//               <li key={user.id}>
//                 ID: {user.id}, Name: {user.name}, Email: {user.email}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default AdminProfile;

import React, { useState, useEffect, useCallback } from "react";
import "./admin.css";

const AdminProfile = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [apiResponse, setApiResponse] = useState([]);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState(""); // State for amount input

  // Function to get the token from local storage
  const getToken = () => {
    return localStorage.getItem("access_token"); // Replace 'token' with the key you're using
  };

  // Fetch user data based on the query
  const fetchResults = useCallback(async () => {
    const token = getToken(); // Retrieve token from local storage

    if (query.length > 0) {
      try {
        const response = await fetch(
          `https://devapi.ivoz.ai/admin/users?page=1&per_page=10`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`, // Use token from local storage
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Response:", data);

        if (Array.isArray(data)) {
          const filteredResults = data.filter((user) =>
            user.email.toLowerCase().includes(query.toLowerCase())
          );
          setResults(filteredResults);
        } else {
          setError("Unexpected response format.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data. Please check your authorization.");
      }
    } else {
      setResults([]);
    }
  }, [query]);

  // Handle search when the button is clicked or Enter key is pressed
  const handleSearch = async () => {
    const token = getToken(); // Retrieve token from local storage

    if (selectedUser) {
      try {
        const response = await fetch(
          `https://devapi.ivoz.ai/admin/users?email=${encodeURIComponent(
            selectedUser.email
          )}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`, // Use token from local storage
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Post Search Response:", data);

        if (Array.isArray(data)) {
          setApiResponse(data);
        } else {
          setError("Unexpected response format.");
        }
      } catch (error) {
        console.error("Error performing search:", error);
        setError("Failed to perform search. Please check your authorization.");
      }
    }
  };

  // Handle amount submission
  const handleAmountSubmit = async () => {
    const token = getToken(); // Retrieve token from local storage

    if (selectedUser && amount) {
      try {
        const response = await fetch(
          "https://devapi.ivoz.ai/admin/modify-balance",
          {
            method: "POST",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`, // Use token from local storage
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user: selectedUser.email,
              log: "string",
              amount: parseFloat(amount),
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Amount Submission Response:", data);
        alert("Amount Submission Response:", data)

        if (data.message === "Amount Adjusted Successfully") {
          // Optionally handle successful submission, e.g., show a success message
          setAmount(""); // Clear the amount input
        } else {
          setError("Failed to adjust amount.");
        }
      } catch (error) {
        console.error("Error submitting amount:", error);
        setError("Failed to submit amount. Please check your authorization.");
      }
    } else {
      setError("Please select a user and enter an amount.");
    }
  };

  // Fetch results whenever query changes
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  return (
    <div className="search-container">
      <input
        className="searchInput"
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search users..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
      />
      <button className="searchSubmit" onClick={handleSearch}>
        Submit
      </button>
      {query.length > 0 && (
        <ul className="search-results">
          {results.map((user) => (
            <li
              key={user.id}
              onClick={() => {
                setSelectedUser(user);
                setQuery(user.email); // Optionally, set the query to the selected email
                setResults([]); // Clear results after selection
              }}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderBottom: "1px solid #ddd",
              }}
            >
              {user.email}
            </li>
          ))}
        </ul>
      )}
      {apiResponse.length > 0 && (
        <div className="api-response">
          <h2>Users Detail:</h2>
          <ul>
            {apiResponse.map((user) => (
              <li key={user.id}>
                <ul>
                  <li>ID: {user.id}</li>
                  <li>Name: {user.name}</li>
                  <li>Email: {user.email}</li>
                </ul>
              </li>
            ))}
          </ul>
          <div className="amount-container">
            <h2>Amount</h2>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <button className="searchSubmit" onClick={handleAmountSubmit}>
              Submit Amount
            </button>
          </div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default AdminProfile;
