// import React, { useState, useEffect } from "react";

// export default function AdminPayment() {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");

//       if (!accessToken) {
//         console.error("No access token found in local storage");
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/users?page=1&per_page=10",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data);
//       } else {
//         console.error("Failed to fetch users");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <>
//       {/* History Table */}
//       <div className="history">
//         <h2>History</h2>
//         <table>
//           <thead>
//             <tr>
//               <th>Sr No</th>
//               <th>User Name</th>
//               <th>Email</th>
//               <th>Credit Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {users.map((user, index) => (
//               <tr key={user.id}>
//                 <td>{index + 1}.</td>
//                 <td>{user.name}</td>
//                 <td>{user.email}</td>
//                 <td className="primary">Credit</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";

export default function AdminPayment() {
  const [users, setUsers] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null); // State to store the selected user's index
  const [creditAmount, setCreditAmount] = useState(""); // State to store the credit amount

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        console.error("No access token found in local storage");
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=10",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle the click on the "Credit" button
  const handleCreditClick = (index) => {
    setSelectedUserIndex(index); // Set the selected user's index to show the input field
    setCreditAmount(""); // Reset the credit amount
  };

  // Function to handle the submission of the credit amount
  const handleCreditSubmit = async (userEmail) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await fetch("https://devapi.ivoz.ai/admin/modify-balance", {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user: userEmail,
          log: "Credited by admin",
          amount: parseFloat(creditAmount),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message); // Show success message
        setSelectedUserIndex(null); // Hide the input field after successful API call
      } else {
        console.error("Failed to adjust amount");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* History Table */}
      <div className="history">
        <h2>History</h2>
        <table>
          <thead>
            <tr>
              <th>Sr No</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Credit Amount</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}.</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {selectedUserIndex === index ? (
                    // Render input box if the current row is selected
                    <>
                      <input
                        type="number"
                        placeholder="Amount"
                        value={creditAmount}
                        onChange={(e) => setCreditAmount(e.target.value)}
                      />
                      <button onClick={() => handleCreditSubmit(user.email)}>yes</button>
                      <button onClick={() => setSelectedUserIndex(null)}>no</button>
                    </>
                  ) : (
                    // Render Credit button if the current row is not selected
                    <button onClick={() => handleCreditClick(index)}>Credit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
