// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// export default function Payment() {
//   const [transactions, setTransactions] = useState([]); // State to store transactions
//   const [page, setPage] = useState(1); // State to manage pagination
//   const [showAll, setShowAll] = useState(false); // State to toggle "Show All"

//   useEffect(() => {
//     fetchTransactions(page);
//   }, [page]); // Fetch transactions when page changes

//   const fetchTransactions = async (pageNumber) => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }
//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=10`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         if (pageNumber > 1) {
//           // Append new data to the existing transactions
//           setTransactions((prev) => [...prev, ...data]);
//         } else {
//           // Set new data for the first page
//           setTransactions(data);
//         }
//       } else {
//         console.error('Failed to fetch transactions');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const handleShowAll = () => {
//     setShowAll(true);
//     setPage((prevPage) => prevPage + 1);
//   };

//   return (
//     <>
//       <div>
//         <h1>Payment</h1>
//       </div>
//       <div className="transaction-table">
//         <div className="history">
//           <h2>Transaction History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Type</th>
//                 <th>Amount</th>
//                 <th>Date</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {transactions.map((txn, index) => (
//                 <tr key={txn.id}>
//                   <td>{index + 1}</td>
//                   <td>{txn.txn_type}</td>
//                   <td>${txn.amount}</td>
//                   <td>{new Date(txn.created_on).toLocaleDateString()}</td>
//                   <td className="primary">Details</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {!showAll && (
//             <Link to="#" onClick={handleShowAll}>
//               Show All
//             </Link>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// export default function Payment() {
//   const [transactions, setTransactions] = useState([]); // State to store transactions
//   const [callLogs, setCallLogs] = useState([]); // State to store call logs
//   const [page, setPage] = useState(1); // State to manage pagination
//   const [showAllTransactions, setShowAllTransactions] = useState(false); // State to toggle "Show All" for transactions
//   const [showAllCallLogs, setShowAllCallLogs] = useState(false); // State to toggle "Show All" for call logs

//   useEffect(() => {
//     fetchTransactions(page);
//     fetchCallLogs(page);
//   }, [page]); // Fetch transactions and call logs when page changes

//   const fetchTransactions = async (pageNumber) => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }
//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=10`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         if (pageNumber > 1) {
//           setTransactions((prev) => [...prev, ...data]);
//         } else {
//           setTransactions(data);
//         }
//       } else {
//         console.error('Failed to fetch transactions');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const fetchCallLogs = async (pageNumber) => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }
//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/call-logs?page=${pageNumber}&per_page=10`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         if (pageNumber > 1) {
//           setCallLogs((prev) => [...prev, ...data]);
//         } else {
//           setCallLogs(data);
//         }
//       } else {
//         console.error('Failed to fetch call logs');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const handleShowAllTransactions = () => {
//     setShowAllTransactions(true);
//     setPage((prevPage) => prevPage + 1);
//   };

//   const handleShowAllCallLogs = () => {
//     setShowAllCallLogs(true);
//     setPage((prevPage) => prevPage + 1);
//   };

//   return (
//     <>
//       <div>
//         <h1>Payment</h1>
//       </div>
//       <div className="transaction-table">
//         <div className="history">
//           <h2>Transaction History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Type</th>
//                 <th>Amount</th>
//                 <th>Date</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {transactions.map((txn, index) => (
//                 <tr key={txn.id}>
//                   <td>{index + 1}</td>
//                   <td>{txn.txn_type}</td>
//                   <td>${txn.amount}</td>
//                   <td>{new Date(txn.created_on).toLocaleDateString()}</td>
//                   <td className="primary">Details</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {!showAllTransactions && (
//             <Link to="#" onClick={handleShowAllTransactions}>
//               Show All Transactions
//             </Link>
//           )}
//         </div>

//         {/* Call Log History Table */}
//         <div className="history">
//           <h2>Call Log History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Bot Phone</th>
//                 <th>From Number</th>
//                 <th>Start Time</th>
//                 <th>End Time</th>
//                 <th>Duration (s)</th>
//                 <th>Units Consumed</th>
//               </tr>
//             </thead>
//             <tbody>
//               {callLogs.map((log, index) => (
//                 <tr key={log.call_id}>
//                   <td>{index + 1}</td>
//                   <td>{log.bot_phone}</td>
//                   <td>{log.from_number}</td>
//                   <td>{new Date(log.start_time).toLocaleString()}</td>
//                   <td>{new Date(log.end_time).toLocaleString()}</td>
//                   <td>{log.duration}</td>
//                   <td>{log.unit_consumed}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {!showAllCallLogs && (
//             <Link to="#" onClick={handleShowAllCallLogs}>
//               Show All Call Logs
//             </Link>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';

// export default function Payment() {
//   const [transactions, setTransactions] = useState([]);
//   const [callLogs, setCallLogs] = useState([]);
//   const [displayedCallLogs, setDisplayedCallLogs] = useState([]);
//   const [displayedTransactions, setDisplayedTransactions] = useState([]);
//   const [page, setPage] = useState(1);
//   const [transactionPage, setTransactionPage] = useState(1);

//   const logsPerPage = 5;
//   const transactionsPerPage = 5;

//   // Fetch transactions when the transactionPage changes
//   useEffect(() => {
//     fetchTransactions(transactionPage);
//   }, [transactionPage]);

//   // Fetch call logs once on component mount
//   useEffect(() => {
//     fetchCallLogs();
//   }, []);

//   const fetchTransactions = async (pageNumber) => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }

//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=${transactionsPerPage}`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setTransactions((prev) => [...prev, ...data]); // Append new transactions to the existing list
//       } else {
//         console.error('Failed to fetch transactions');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const fetchCallLogs = async () => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }
//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/call-logs?per_page=50`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setCallLogs(data);
//       } else {
//         console.error('Failed to fetch call logs');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const updateDisplayedCallLogs = useCallback(() => {
//     const startIndex = (page - 1) * logsPerPage;
//     const endIndex = startIndex + logsPerPage;
//     setDisplayedCallLogs(callLogs.slice(startIndex, endIndex));
//   }, [page, callLogs]);

//   const updateDisplayedTransactions = useCallback(() => {
//     const startIndex = (transactionPage - 1) * transactionsPerPage;
//     const endIndex = startIndex + transactionsPerPage;
//     setDisplayedTransactions(transactions.slice(startIndex, endIndex));
//   }, [transactionPage, transactions]);

//   // Update displayed call logs when call logs or page changes
//   useEffect(() => {
//     updateDisplayedCallLogs();
//   }, [updateDisplayedCallLogs]);

//   // Update displayed transactions when transactions or transaction page changes
//   useEffect(() => {
//     updateDisplayedTransactions();
//   }, [updateDisplayedTransactions]);

//   const handleNextCallLogs = () => {
//     if (page * logsPerPage < callLogs.length) {
//       setPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePreviousCallLogs = () => {
//     if (page > 1) {
//       setPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleNextTransactions = () => {
//     if (transactionPage * transactionsPerPage < transactions.length) {
//       setTransactionPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePreviousTransactions = () => {
//     if (transactionPage > 1) {
//       setTransactionPage((prevPage) => prevPage - 1);
//     }
//   };

//   return (
//     <>
//       <div>
//         <h1>Payment</h1>
//       </div>
//       <div className="transaction-table">
//         <div className="history">
//           <h2>Transaction History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Type</th>
//                 <th>Amount</th>
//                 <th>Date</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {displayedTransactions.map((txn, index) => (
//                 <tr key={txn.id}>
//                   <td>{(transactionPage - 1) * transactionsPerPage + index + 1}</td>
//                   <td>{txn.txn_type}</td>
//                   <td>${txn.amount}</td>
//                   <td>{new Date(txn.created_on).toLocaleDateString()}</td>
//                   <td className="primary">Details</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <div className='nextPrev'>
//             {transactionPage > 1 && (
//               <Link to="#" onClick={handlePreviousTransactions}>
//                 Previous 5 Transactions
//               </Link>
//             )}
//             {transactionPage * transactionsPerPage < transactions.length && (
//               <Link to="#" onClick={handleNextTransactions}>
//                 Next 5 Transactions
//               </Link>
//             )}
//           </div>
//         </div>

//         <div className="history">
//           <h2>Call Log History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Bot Phone</th>
//                 <th>From Number</th>
//                 <th>Start Time</th>
//                 <th>End Time</th>
//                 <th>Duration (s)</th>
//                 <th>Units Consumed</th>
//               </tr>
//             </thead>
//             <tbody>
//               {displayedCallLogs.map((log, index) => (
//                 <tr key={log.call_id}>
//                   <td>{(page - 1) * logsPerPage + index + 1}</td>
//                   <td>{log.bot_phone}</td>
//                   <td>{log.from_number}</td>
//                   <td>{new Date(log.start_time).toLocaleString()}</td>
//                   <td>{new Date(log.end_time).toLocaleString()}</td>
//                   <td>{log.duration}</td>
//                   <td>{log.unit_consumed}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <div className='nextPrev'>
//             {page > 1 && (
//               <Link to="#" onClick={handlePreviousCallLogs}>
//                 Previous 5 Logs
//               </Link>
//             )}
//             {page * logsPerPage < callLogs.length && (
//               <Link to="#" onClick={handleNextCallLogs}>
//                 Next 5 Logs
//               </Link>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './mainboard.css';

export default function TransactionHistory() {
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [selectedTransactions, setSelectedTransactions] = useState(new Set());
  const [selectAllTransactions, setSelectAllTransactions] = useState(false);

  const transactionsPerPage = 10; // Updated pagination size

  useEffect(() => {
    fetchTransactions(transactionPage);
  }, [transactionPage]);

  const fetchTransactions = async (pageNumber) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        console.error('No access token found in local storage');
        return;
      }

      const response = await fetch(
        `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=${transactionsPerPage}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTransactions((prev) => [...prev, ...data]);
      } else {
        console.error('Failed to fetch transactions');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateDisplayedTransactions = useCallback(() => {
    const startIndex = (transactionPage - 1) * transactionsPerPage;
    const endIndex = startIndex + transactionsPerPage;
    setDisplayedTransactions(transactions.slice(startIndex, endIndex));
  }, [transactionPage, transactions]);

  useEffect(() => {
    updateDisplayedTransactions();
  }, [updateDisplayedTransactions]);

  const handleNextTransactions = () => {
    if (transactionPage * transactionsPerPage < transactions.length) {
      setTransactionPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousTransactions = () => {
    if (transactionPage > 1) {
      setTransactionPage((prevPage) => prevPage - 1);
    }
  };

  const handleSelectTransaction = (id) => {
    setSelectedTransactions((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleSelectAllTransactions = () => {
    setSelectAllTransactions((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      setSelectedTransactions(newSelectAll
        ? new Set(displayedTransactions.map((txn) => txn.id))
        : new Set()
      );
      return newSelectAll;
    });
  };

  const downloadCSV = (data, filename) => {
    const csvContent = data.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadTransactions = () => {
    if (selectedTransactions.size === 0) {
      return; // Do nothing if no transactions are selected
    }
    const filteredTransactions = displayedTransactions.filter((txn) => selectedTransactions.has(txn.id));
    const csvData = [['Sr No', 'Type', 'Amount', 'Date']].concat(
      filteredTransactions.map((txn, index) => [
        (transactionPage - 1) * transactionsPerPage + index + 1,
        txn.txn_type,
        txn.amount,
        new Date(txn.created_on).toLocaleDateString()
      ])
    );
    downloadCSV(csvData, 'transactions.csv');
  };

  const isDownloadButtonDisabled = selectedTransactions.size === 0;

  return (
    <>
      <div>
        <h1>Transaction History</h1>
      </div>
      <div className="transaction-table">
        <div className="history">
          <div className="tableUpr">
            <h2>Transaction History</h2>
            <button
              className='searchSubmit'
              onClick={handleDownloadTransactions}
              disabled={isDownloadButtonDisabled}
            >
              Download CSV
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAllTransactions}
                    onChange={handleSelectAllTransactions}
                  />
                </th>
                <th>Sr No</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {displayedTransactions.map((txn, index) => (
                <tr key={txn.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedTransactions.has(txn.id)}
                      onChange={() => handleSelectTransaction(txn.id)}
                    />
                  </td>
                  <td>{(transactionPage - 1) * transactionsPerPage + index + 1}</td>
                  <td>{txn.txn_type}</td>
                  <td>${txn.amount}</td>
                  <td>{new Date(txn.created_on).toLocaleDateString()}</td>
                  <td className="primary">Details</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='nextPrev'>
            {transactionPage > 1 && (
              <Link to="#" onClick={handlePreviousTransactions}>
                Previous 10 Transactions
              </Link>
            )}
            {transactionPage * transactionsPerPage < transactions.length && (
              <Link to="#" onClick={handleNextTransactions}>
                Next 10 Transactions
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
