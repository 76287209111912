// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "./mainboard.css";

// export default function Home() {
//   const [accountBal, setAccountBal] = useState("");
//   const [bots, setBots] = useState([]); // State to store bots data

//   useEffect(() => {
//     // Fetch the user's profile from the API
//     const fetchAccountDetails = async () => {
//       const token = localStorage.getItem("access_token"); // Get the token from local storage
//       if (!token) return; // If no token, exit

//       try {
//         const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`, // Include the bearer token
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setAccountBal(data.balance); // Set the user's balance in state
//           setBots(data.bots); // Set the bots data in state
//         } else {
//           console.error("Failed to fetch user data");
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchAccountDetails();
//   }, []);

//   return (
//     <>
//       <div className="main-inner">
//         <div className="upperMiddle">
//           <h1>Dashboard</h1>

//           <div className="date">
//             <input type="date" />
//           </div>
//         </div>
//         <div className="insights">
//           {/* Account Balance  */}
//           <div className="accountBal">
//             <span className="material-icons-sharp">analytics</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Account Balance</h3>
//                 <h1>$ {accountBal}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>81%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Account Balance  */}

//           {/* On Hold Balance */}
//           <div className="onHoldBal">
//             <span className="material-icons-sharp">bar_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>On-hold Balance</h3>
//                 <h1>$200</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>41%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* On hold Balance  */}

//           {/* Total Spent  */}
//           <div className="expenses">
//             <span className="material-icons-sharp">stacked_line_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Total Expense</h3>
//                 <h1>$2500</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>44%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Total Spent  */}
//         </div>

//         <div className="history">
//           <h2>History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Bot ID</th> {/* New column for bot_id */}
//                 <th>Bot Name</th>
//                 <th>Bot Phone</th>
//                 <th>Status</th>
//                 <th></th>
//               </tr>
//             </thead>

//             <tbody>
//               {bots.map((bot, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{bot.bot_id}</td> {/* Added bot_id column */}
//                   <td>{bot.bot_name}</td>
//                   <td>{bot.bot_phone}</td>
//                   <td className="warning">Pending</td>
//                   <td className="primary">Details</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Link to="#">Show All</Link>
//         </div>
//       </div>
//     </>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "./mainboard.css";

// export default function Home() {
//   const [accountBal, setAccountBal] = useState("");
//   const [bots, setBots] = useState([]); // State to store bots data
//   const [callLogs, setCallLogs] = useState([]); // State to store call logs
//   const [selectedBotId, setSelectedBotId] = useState(null); // State to store selected bot_id

//   useEffect(() => {
//     // Fetch the user's profile from the API
//     const fetchAccountDetails = async () => {
//       const token = localStorage.getItem("access_token"); // Get the token from local storage
//       if (!token) return; // If no token, exit

//       try {
//         const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`, // Include the bearer token
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setAccountBal(data.balance); // Set the user's balance in state
//           setBots(data.bots); // Set the bots data in state
//         } else {
//           console.error("Failed to fetch user data");
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchAccountDetails();
//   }, []);

//   const fetchCallLogs = async (botId) => {
//     // Fetch call logs for the selected bot
//     const token = localStorage.getItem("access_token");
//     if (!token) return;

//     try {
//       const response = await fetch(`https://devapi.ivoz.ai/users/call-logs?bot_id=${botId}&page=1&per_page=10`, {
//         method: "GET",
//         headers: {
//           accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCallLogs(data); // Set the call logs in state
//         setSelectedBotId(botId); // Set the selected bot ID in state
//       } else {
//         console.error("Failed to fetch call logs");
//       }
//     } catch (error) {
//       console.error("Error fetching call logs:", error);
//     }
//   };

//   return (
//     <>
//       <div className="main-inner">
//         <div className="upperMiddle">
//           <h1>Dashboard</h1>

//           <div className="date">
//             <input type="date" />
//           </div>
//         </div>
//         <div className="insights">
//           {/* Account Balance  */}
//           <div className="accountBal">
//             <span className="material-icons-sharp">analytics</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Account Balance</h3>
//                 <h1>$ {accountBal}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>81%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Account Balance  */}

//           {/* On Hold Balance */}
//           <div className="onHoldBal">
//             <span className="material-icons-sharp">bar_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>On-hold Balance</h3>
//                 <h1>$200</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>41%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* On hold Balance  */}

//           {/* Total Spent  */}
//           <div className="expenses">
//             <span className="material-icons-sharp">stacked_line_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Total Expense</h3>
//                 <h1>$2500</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>44%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Total Spent  */}
//         </div>

//         <div className="history">
//           <h2>History</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Bot ID</th>
//                 <th>Bot Name</th>
//                 <th>Bot Phone</th>
//                 <th>Status</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {bots.map((bot, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{bot.bot_id}</td>
//                   <td>{bot.bot_name}</td>
//                   <td>{bot.bot_phone}</td>
//                   <td className="warning">Pending</td>
//                   <td className="primary" onClick={() => fetchCallLogs(bot.bot_id)}>Details</td> {/* Add onClick event */}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Link to="#">Show All</Link>

//           {/* Render Call Logs */}
//           {selectedBotId && callLogs.length > 0 && (
//             <div className="call-logs">
//               <h2>Call Logs for Bot ID: {selectedBotId}</h2>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Call ID</th>
//                     <th>Bot Phone</th>
//                     <th>From Number</th>
//                     <th>Start Time</th>
//                     <th>End Time</th>
//                     <th>Duration (s)</th>
//                     <th>Units Consumed</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {callLogs.map((log, index) => (
//                     <tr key={index}>
//                       <td>{log.call_id}</td>
//                       <td>{log.bot_phone}</td>
//                       <td>{log.from_number}</td>
//                       <td>{new Date(log.start_time).toLocaleString()}</td>
//                       <td>{new Date(log.end_time).toLocaleString()}</td>
//                       <td>{log.duration}</td>
//                       <td>{log.unit_consumed}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./mainboard.css";

export default function Home() {
  const [accountBal, setAccountBal] = useState("");
  const [bots, setBots] = useState([]); // State to store bots data

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAccountBal(data.balance);
          setBots(data.bots);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchAccountDetails();
  }, []);

  return (
    <>
      <div className="main-inner">
        <div className="upperMiddle">
          <h1>Dashboard</h1>

          <div className="date">
            <input type="date" />
          </div>
        </div>
        <div className="insights">
          {/* Account Balance  */}
          <div className="accountBal">
            <span className="material-icons-sharp">analytics</span>
            <div className="middle">
              <div className="left">
                <h3>Account Balance</h3>
                <h1>$ {accountBal}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>81%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Account Balance  */}

          {/* On Hold Balance */}
          <div className="onHoldBal">
            <span className="material-icons-sharp">bar_chart</span>
            <div className="middle">
              <div className="left">
                <h3>On-hold Balance</h3>
                <h1>$200</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>41%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* On hold Balance  */}

          {/* Total Spent  */}
          <div className="expenses">
            <span className="material-icons-sharp">stacked_line_chart</span>
            <div className="middle">
              <div className="left">
                <h3>Total Expense</h3>
                <h1>$2500</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>44%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Total Spent  */}
        </div>

        <div className="history">
          <h2>Campaigns</h2>
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Bot ID</th>
                <th>Bot Name</th>
                <th>Bot Phone</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bots.map((bot, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{bot.bot_id}</td>
                  <td>{bot.bot_name}</td>
                  <td>{bot.bot_phone}</td>
                  <td className="warning">Pending</td>
                  <td className="primary">
                    <Link to={`/userboard/bot-details/${bot.bot_id}`}>Details</Link> {/* Link to the details page */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link to="#">Show All</Link>
        </div>
      </div>
    </>
  );
}