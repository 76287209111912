import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function AdminHome() {
  const [users, setUsers] = useState([]); // State to store user data

  useEffect(() => {
    fetchUsers(); // Fetch users when the component mounts
  }, []);

  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        console.error("No access token found in local storage");
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=10",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data); // Store the user data in the state
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="main-inner">
        <div className="upperMiddle">
          <h1>Dashboard</h1>

          <div className="date">
            <input type="date" />
          </div>
        </div>
        <div className="insights">
          {/* Account Balance */}
          <div className="accountBal">
            <span className="material-icons-sharp">analytics</span>
            <div className="middle">
              <div className="left">
                <h3>Account Balance</h3>
                <h1>$250</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>81%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Account Balance */}

          {/* On Hold Balance */}
          <div className="onHoldBal">
            <span className="material-icons-sharp">bar_chart</span>
            <div className="middle">
              <div className="left">
                <h3>On-hold Balance</h3>
                <h1>$200</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>41%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF On Hold Balance */}

          {/* Total Spent */}
          <div className="expenses">
            <span className="material-icons-sharp">stacked_line_chart</span>
            <div className="middle">
              <div className="left">
                <h3>Total Expense</h3>
                <h1>$2500</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>44%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Total Spent */}
        </div>

        <div className="history">
          <h2>History</h2>
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Project Running</th>
                <th>Project Finished</th>
                <th>Total Project</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}.</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>null</td>
                  <td>null</td>
                  <td className="warning">Pending</td>
                  <td className="primary">Details</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link to="#">Show All</Link>
        </div>
      </div>
    </>
  );
}

