import React from "react";

export default function Accessmanager() {
  return (
    <div>
      <h1>Invite to Access</h1>
      <p>
        Enter a name and email address for the person you'd like to grant
        account access. Then, select an access level and click Invite.
      </p>

      <div className="accessManagerInputs">
        <label htmlFor="Name">Name</label>
        <br />
        <input type="text" />
        <br />
        <label htmlFor="Email">Email</label>
        <br />
        <input type="email" />
      </div>
      <hr />

      <div className="giveAccessBlock">
        <h2>Access Level requested <span className="danger">*</span></h2>
        <br />
        <div className="checkBoxes">
        <input type="checkbox" />
        <label htmlFor="1"><b>Billing & Profile & Projects</b> <br /> Access to manage projects, profile, and billing using credit cards stored in profile.</label>
        </div>
        <div className="checkBoxes">
        <input type="checkbox" />
        <label htmlFor="2"> <b>Billing & Projects</b> <br />Access to manage projects and billing only. </label>
        </div>
        <div className="checkBoxes">
        <input type="checkbox" />
        <label htmlFor="3"><b>Projects only</b> <br /> Access to manage specific projects only. </label>
        </div>
      </div>

      <div className="accessButton">
        <div className="updateButton">
          <button>Update</button>
        </div>
        <div className="cancelButton">
          <button>Cancel</button>
        </div>
      </div>
    </div>
  );
}
